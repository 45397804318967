import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'src/App';
import store, { persistor } from 'src/app/store';

import { startExpiryWatcher } from 'src/data/services/availability';

import { Loading } from './app/pages/Loading/Loading';
import './app/styles/global.scss';

const mountElement = document.getElementById('app');

/**
 * Disable browsers' translation of the app because it will break React.
 * See: https://github.com/facebook/react/issues/11538
 */
mountElement?.setAttribute('notranslate', 'true');
mountElement?.setAttribute('translate', 'no');

startExpiryWatcher();
// initThemeStyle();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Loading />} persistor={persistor}>
            <React.Suspense fallback="Loading...">
                <App />
            </React.Suspense>
        </PersistGate>
    </Provider>,
    mountElement
);
